import * as React from 'react'
import {useStaticQuery, graphql, navigate} from 'gatsby'
import "./aboutNavigation.scss"
import Select from "../global/select";

const AboutNavigation = (props) => {
    const data = useStaticQuery(graphql`
        {
            craft {
                menu: navigationNodes(navHandle: "aboutUsSectionNavigation", level: 1, status: null) {
                    title
                    nodeUri
                    enabled
                    children {
                        id
                        title
                        nodeUri
                        value: nodeUri
                    }
                }
            }
        }
    `)

    const getUrl = (node) => {
        // Test if empty
        if (node.nodeUri) {
            // Test if absolute URL
            var r = new RegExp('^(?:[a-z]+:)?//', 'i');
            if (r.test(node.nodeUri)) {
                return node.nodeUri
            } else {
                return `/${node.nodeUri}`
            }
        } else {
            return '#'
        }
    }

    const getLink = (node) => {
        if (node.nodeUri === props.active) {
            return (
                <a className="active">{node.title}</a>
            )
        } else {
            return (
                <a href={getUrl(node)}>{node.title}</a>
            )
        }
    }

    const navWalker = (nodes, child = false) => {
        if (!nodes || nodes.length === 0) {
            return null
        }

        if (child) {
            return nodes.map(node => (
                <li key={node.id}>{getLink(node)}</li>
            ))
        } else {
            return nodes.map(node => (
                <div className="desktop">
                    {node.nodeUri === "" && node.enabled === true ? (
                        <h3>{node.title}</h3>
                    ):(
                        null
                    )}
                    <ul>{ navWalker(node.children, true) }</ul>
                </div>
            ))
        }
    }

    const changeNavigationItem = (event) => {
        navigate(`/${event.target.value}`)
    }

    // Set selected newsetter topic
    data.craft.menu[0].children.map(item => {
        if (item.nodeUri === props.active) {
            item.selected = true
        }
        return item
    })

    return (
        <div className="about-us-navigation">
            <Select changeHandler={changeNavigationItem} classes="mobile" options={data.craft.menu[0].children}></Select>
            {navWalker(data.craft.menu)}
        </div>
    )
}

export default AboutNavigation
import * as React from "react"
import {graphql} from "gatsby";
import AboutNavigation from "../components/about/aboutNavigation";
import HeroImage from "../components/global/heroImage";
import InnerLayout from "../components/global/innerLayout"
import PageTitle from "../components/global/pageTitle";
import parse from "html-react-parser";

export const query = graphql`
    query($id: [Craft_QueryArgument]) {
      craft {
        page: entry(section: "aboutUsBasicPage", id: $id) {
          ... on Craft_aboutUsBasicPage_default_Entry {
          seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            title
            uri
            aboutUsBodyDefault
            aboutUsHeroImage {
              ... on Craft_aboutUsHeroImage_image_BlockType {
                image {
                  url(transform: "heroImageTransform")
                  ... on Craft_websiteGeneral_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
          }
        }
      }
    }
  `

const AboutBasicPage = ({ data, pageContext }) => {

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd about-basic-page">

                <PageTitle title={data.craft.page.title} />
                <HeroImage image={data.craft.page.aboutUsHeroImage}/>

                <div className="content-page">
                    <div className="container-small">

                        <div className="content-colum-row">
                            <div className="sidebar">
                                <AboutNavigation active={data.craft.page.uri} />
                            </div>
                            <div className="content-right">

                                {parse(data.craft.page.aboutUsBodyDefault)}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default AboutBasicPage

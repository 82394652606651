import * as React from "react"
import Image from "./image"
import ImageLink from "./imageLink"
import "./heroImage.scss"

const HeroImage = (props) => {
    if (props.image.length > 0 && props.image[0].image.length > 0) {
        if (props.image[0].imageLink && props.image[0].imageLink.length > 0) {
            return (
                <div className="static-page-banner">
                    <ImageLink src={props.image[0].image[0].url} alt={props.image[0].image[0].altText} to={props.image[0].imageLink[0].linkUrl} openInNewTab={props.image[0].imageLink[0].openInNewTab} />
                </div>
            )
        } else {
            return (
                <div className="static-page-banner">
                    <Image src={props.image[0].image[0].url} alt={props.image[0].image[0].altText} />
                </div>
            )
        }
    } else {
        return null
    }
}

export default HeroImage
import * as React from 'react'

import InnerPageMainNavigation from "./innerPageMainNavigation"
import GlobalAnnouncement from "./globalAnnouncement"
import Footer from "./footer"
import NewsletterSignup from "./newsletterSignup"
import './innerLayout.scss'
import Seo from "./seo";

const InnerLayout = ({ seomatic, children, entryId, forceNewsletterSignupEnabled }) => {
    return (
        <div className="wrapper">
            <Seo seomatic={seomatic} />
            <InnerPageMainNavigation />
            <GlobalAnnouncement/>
            {children}
            <NewsletterSignup entryId={entryId} forceEnabled={forceNewsletterSignupEnabled} />
            <Footer/>
        </div>
    )
}

export default InnerLayout
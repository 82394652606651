import * as React from "react"
import './pageTitle.scss'

const PageTitle = (props) => {
    return (
        <div className={`page-title ${props.className}`}>
            <div className="container">
                <h2>{props.title}</h2>
            </div>
        </div>
    )
}

export default PageTitle
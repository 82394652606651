import * as React from 'react'
import "./select.scss"

const Select = (props) => {
    const getIsSelected = (option) => {
        if (option.selected) {
            return true
        } else {
            return false
        }
    }

    const getOptions = (options) => {
        return options.map(option => (
            <option key={option.value} value={option.value} selected={getIsSelected(option)} disabled={option.disabled || false}>{option.title}</option>
        ))
    }

    const getClasses = (classes) => {
        return "select " + classes
    }

    return (
        <div className={getClasses(props.classes)}>
            <select onChange={props.changeHandler} name={props.name} required={props.required || false}>
                {getOptions(props.options)}
            </select>
            <span className="focus"></span>
        </div>
    )
}

export default Select
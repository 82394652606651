import * as React from 'react'

// Components
import Image from "./image"

const ImageLink = (props) => {
    const getTarget = (openInNewTab) => {
        if (openInNewTab) {
            return "_blank"
        } else {
            return "_self"
        }
    }

    return (
        <a href={props.to} target={getTarget(props.openInNewTab)}><Image src={props.src} alt={props.alt}/></a>
    )
}

export default ImageLink